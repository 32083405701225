import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../components/Layout';
import Scroll from '../../components/Scroll';
import constants from '../../constants';
import { Link } from 'gatsby';
import { LightgalleryItem } from 'react-lightgallery';
import img1 from '../../assets/images/gallery/1.jpg';
import img2 from '../../assets/images/gallery/2.jpg';
import img3 from '../../assets/images/gallery/3.jpg';
import img4 from '../../assets/images/gallery/4.jpg';
import img5 from '../../assets/images/gallery/5.jpg';
import img6 from '../../assets/images/gallery/6.jpg';
import img7 from '../../assets/images/gallery/7.jpg';

const IndexPage = () => (
  <Layout>
    <a href="/hu/palyazatok">
      <StaticImage
        style={{
          position: 'fixed',
          right: '0',
          bottom: '0',
          width: '25%',
          minWidth: '300px',
          zIndex: 9,
        }}
        src="../../assets/images/infog2.png"
        alt=""
      />
    </a>
    <section id="banner">
      <div className="inner">
        <div
          style={{
            width: '16%',
            paddingBottom: '25px',
            minWidth: '150px',
            margin: 'auto',
          }}
        >
          <StaticImage
            src="../../assets/images/logo.png"
            alt="Juhász Testvérek Logó"
          />
        </div>
        <p>Juhász testvérek • Traditional Wine Cellar • Egerszalók</p>
        <ul className="actions special">
          <li>
            <a
              href={constants.WEBSHOP_LINK}
              target="__blank"
              className="button primary"
            >
              Webshop
            </a>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="about-us">
        <a href="#about-us" className="more">
          Scroll down!
        </a>
      </Scroll>
    </section>

    <section id="about-us" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <StaticImage src="../../assets/images/rolunk.jpg" alt="Rólunk" />
        </div>
        <div className="content">
          <h2>About us</h2>
          <p>
            The winery, founded in 1996 by Attila Juhász and his brother,
            celebrated its 25th anniversary in 2021. The family winery began as
            a tiny, roughly 1.5-hectare smallholding. The first big leap in the
            winery’s life was in 2002, when nearly 10 hectares of newly planted
            vines came into production, including some in outstanding vineyards
            such as Paptag, while a modern winery was built in the cellar on
            Kőlyuktető in Eger. 2010 was also a year of outstanding importance.
            This was when the Juhász Rosé was launched, bringing national
            popularity to the winery. In 2018, second generation Ádám Juhász
            took over the management of the winery and the winemaking. That same
            year, the winery’s new winemaking facility, featuring some of the
            most advanced technology in the country, was handed over. Today, the
            Juhász brothers’ wines are made from the fruit of nearly 350
            hectares of vines. They are renowned for their high quality, pure
            fruit and unmistakeable varietal character. They have also won
            numerous medals in many Hungarian and international wine
            competitions.
          </p>
        </div>
      </section>
    </section>

    <section id="two" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>Our philosophy</h2>
          <p>
            Infinite fruitiness resulting from a combination of tradition and
            modern technology.
          </p>
        </header>
      </div>
    </section>

    <section id="three" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <StaticImage
            src="../../assets/images/grapes.jpg"
            alt="Szőlőültetvény"
          />
        </div>
        <div className="content">
          <h2>Vineyards</h2>
          <p>
            Our 350-hectare vineyard is in the Eger wine region. Our most
            important vineyards are located on the edges of Eger, Egerszalók,
            Egerszólát, Demjén, Nagytálya, Andornaktálya and Maklár. Black
            varieties cultivated include Kékfrankos, Cabernet Sauvignon,
            Cabernet Franc, Pinot Noir, Portugieser, Zweigelt, Blauburger and
            Merlot, which form an excellent basis for making Egri Bikavér. Our
            white wines are made from Muscat Ottonel, Irsai Olivér,
            Olaszrizling, Chardonnay, Sauvignon Blanc, Pinot Gris and Leányka.
            We have both old and newly planted vineyards. We employ
            state-of-the-art cultivation methods for new plantings. These
            vineyards are cultivated within the framework of the
            agri-environmental programme. The vineyards are trained using VSP
            and Guyot. Our two most important vineyards are Paptag (10 ha) and
            Gesztenyés (6 ha), familiar names to many. These two vineyards
            produce the winery’s top red wines each year.
          </p>
        </div>
      </section>

      <section className="spotlight">
        <div className="image">
          <StaticImage
            src="../../assets/images/winery.jpg"
            alt="Az Egri Borvidék"
          />
        </div>
        <div className="content">
          <h2>The Eger wine region</h2>
          <p>
            The Eger wine region with its 6,040 hectares of vineyards is in the
            northeast of Hungary, on the western slopes of the Bükk Mountains,
            120 km from the capital Budapest. It is divided into two districts
            in terms of protection of origin: Eger and Debrő. It includes the
            city of Eger and 19 other small towns and villages. It is the
            birthplace of excellent, high-quality, elegant wines, which the
            reddish-brown clay and volcanic soils endow with extremely rich
            aromas and complexity. The city and its surroundings are a famous
            historical wine region, where the traditions of viticulture and
            producing excellent wines date back a thousand years. The Eger wine
            region underwent extremely rapid development following the regime
            change, with its area also significantly increased by replanting
            both old and new grape varieties in the best vineyards.
          </p>
        </div>
      </section>

      {/* <section className="spotlight">
        <div className="image">
          <StaticImage src="../../assets/images/wines.jpeg" alt="Boraink" />
        </div>
        <div className="content">
          <h2>
            Boraink
          </h2>
          <p>
          A legmegfelelőbb termőhely kiválasztásával, a visszafogott terméssel és a legkorszerűbb feldolgozással boraink állandó jó minőségét garantáljuk. Munkánkat igazolja a hazai versenyeken elért aranyérmek és díjak. 
          </p>
          <a href="/boraink" className="button primary">
            Tovább
          </a>
        </div>
      </section> */}
    </section>

    {/* <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            Fejlesztéseink
          </h2>
          <p>
            A modern borászati technológiával...
          </p>
        </header>
      </div>
    </section> */}

    <section className="wrapper style2">
      <div className="inner">
        <section>
          <div className="box alt">
            <div className="row gtr-50 gtr-uniform">
              <div className="col-12">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1}>
                    <StaticImage
                      src="../../assets/images/gallery/1.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2}>
                    <StaticImage
                      src="../../assets/images/gallery/2.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img3}>
                    <StaticImage
                      src="../../assets/images/gallery/3.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img4}>
                    <StaticImage
                      src="../../assets/images/gallery/4.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img5}>
                    <StaticImage
                      src="../../assets/images/gallery/5.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img6}>
                    <StaticImage
                      src="../../assets/images/gallery/6.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img7}>
                    <StaticImage
                      src="../../assets/images/gallery/7.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>Events</h2>
          <p>
            The winery also has a hospitality centre boasting many facilities.
            This not only evokes a classic wine cellar atmosphere but also helps
            visitors discover the wine region and our vineyards and follow the
            path of the grapes from harvesting to bottling. The intimate wine
            tasting corners provide excellent spots for individual wine
            tastings, while the imposing tasting room is also ideal for friends,
            family and corporate events as well as exclusive receptions and
            weddings of up to 100 people.
          </p>
        </header>
        <ul className="features">
          <li>
            <Link to="/en/events#tasting-group">
              <div className="feature-icon">
                <StaticImage
                  src="../../assets/images/icons/glass.png"
                  alt="Group Tasting"
                />
              </div>
              <h4>Group Tasting</h4>
            </Link>
          </li>
          <li>
            <Link to="/en/events#wedding">
              <div className="feature-icon">
                <StaticImage
                  src="../../assets/images/icons/circles.png"
                  alt="Wedding Venue"
                />
              </div>
              <h4>Wedding Venue</h4>
            </Link>
          </li>
          <li>
            <Link to="/en/events#tasting-offsite">
              <div className="feature-icon">
                <StaticImage
                  src="../../assets/images/icons/hand.png"
                  alt="Off-site tasting"
                />
              </div>
              <h4>Off-site tasting</h4>
            </Link>
          </li>
          <li>
            <Link to="/en/events#wine-tasting">
              <div className="feature-icon">
                <StaticImage
                  src="../../assets/images/icons/user.png"
                  alt="Wine tasting"
                />
              </div>
              <h4>Wine tasting</h4>
            </Link>
          </li>
          <li>
            <Link to="/en/events#event-management">
              <div className="feature-icon">
                <StaticImage
                  src="../../assets/images/icons/handshake.png"
                  alt="Event management"
                />
              </div>
              <h4>Event management</h4>
            </Link>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
